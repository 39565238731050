<template>
  <div class="account-profile">
    <template v-if="!editing">
      <div class="form-row">
        <div class="col avatar-wrapper">
          <avatar-image :url="$user.user.picture" />
        </div>
        <div class="col">
          <div class="name">{{ name }}</div>
          <label>CPF</label>
          <div class="value">{{ document }}</div>
          <label>E-mail</label>
          <div class="value">{{ email }}</div>
          <label>Telefone</label>
          <div class="value">{{ phone }}</div>
        </div>
      </div>
    </template>

    <form v-if="editing" @submit.prevent="submit">
      <div class="form-row">
        <div class="col avatar-wrapper">
          <image-input :url="picture" @pick="pickFile" />
        </div>
        <div class="col col-lg-6 col-md-9 pt-3">
          <form-group
            v-model="name"
            :errors="errors.name"
            :readonly="loading"
            id="name"
            type="text"
            placeholder="Digite seu nome"
          />

          <form-group
            v-model="phone"
            :errors="errors.phone"
            :readonly="loading"
            id="phone"
            type="text"
            placeholder="Digite seu telefone"
          />

          <div class="d-flex">
            <button
              type="button"
              class="btn btn-outline-secondary btn-lg px-4 mr-2"
              @click="$emit('close')"
            >Cancelar</button>
            <button
              type="submit"
              class="btn btn-success btn-lg px-4"
              :disabled="loading"
            >
              <loading :show="loading">Salvar</loading>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import ImageInput from './ImageInput'
import AvatarImage from './AvatarImage'

export default {
  components: { ImageInput, AvatarImage },
  props: {
    editing: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      name: this.$user.user.name,
      email: this.$user.user.email,
      document: this.$user.user.document,
      phone: this.$user.user.phone,
      loading: false,
      picture: this.$user.user.picture,
      newPicture: false,
      errors: {
        name: [],
        document: [],
        phone: []
      }
    }
  },
  computed: {
    phoneMask () {
      if (this.phone.length === 10) {
        return '(##)####-########'
      } else {
        return '(##)#####-###############'
      }
    }
  },
  methods: {
    pickFile (file) {
      this.newPicture = true
      this.picture = file
    },
    submit () {
      const data = {
        name: this.name,
        document: this.document,
        phone: this.phone
      }
      if (this.newPicture) { data.picture = this.picture }

      this.loading = true
      this.$store
        .dispatch('user/update', data)
        .then(() => {
          this.$emit('close')
        })
        .catch(error => {
          this.errors = error.response.data
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.account-profile {
  .name {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: rgba(255, 255, 255, 0.8);
  }

  label {
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: rgba(255, 255, 255, 0.4);
  }

  .value {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: rgba(255, 255, 255, 0.8);
  }

  form {
    button {
      min-width: 80px;
    }
  }

  .avatar-wrapper {
    max-width: 70px;
  }
}
</style>
