<template>
  <b-modal
    @change="change"
    :visible="visible"
    hide-footer
    hide-header
    centered
  >
    <div class="forgot-password p-2">
      <h3 class="text-center mb-3">
        Alterar senha
      </h3>

      <form @submit.prevent="submit">
        <div class="position-relative mb-4">
          <form-group
            v-model="password_old"
            class="mb-0"
            id="password_old"
            placeholder="Digite a senha atual"
            :type="oldPasswordInputType"
            :errors="errors.password_old"
          />
          <show-hide-password v-model="oldPasswordInputType" />
        </div>

        <div class="position-relative mb-4">
          <form-group
            v-model="password"
            class="mb-0"
            id="password"
            placeholder="Escolha uma nova senha"
            :type="passwordInputType"
            :errors="errors.password"
          />
          <show-hide-password v-model="passwordInputType" />
        </div>

        <div class="position-relative mb-4">
          <form-group
            v-model="passwordConf"
            class="mb-0"
            id="passwordConf"
            placeholder="Repita a nova senha"
            :type="passwordConfInputType"
            :errors="errors.passwordConf"
          />
          <show-hide-password v-model="passwordConfInputType" />
        </div>

        <div class="row">
          <div class="col pr-4">
            <button
              class="btn btn-block btn-outline-secondary btn-lg"
              type="button"
              @click="close"
            >
              Cancelar
            </button>
          </div>
          <div class="col pl-4">
            <button
              class="btn btn-block btn-primary btn-lg"
              type="submit"
              :disabled="loading"
            >
              <b-spinner v-show="loading" variant="light" small></b-spinner>
              <span v-show="!loading">Enviar</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </b-modal>
</template>

<script>
import ShowHidePassword from '@/components/ShowHidePassword'

export default {
  components: { ShowHidePassword },
  props: {
    visible: {
      type: Boolean,
      defaul: false
    }
  },
  data () {
    return {
      oldPasswordInputType: 'password',
      passwordInputType: 'password',
      passwordConfInputType: 'password',
      loading: false,
      password: '',
      password_old: '',
      passwordConf: '',
      errors: {
        password: null,
        password_old: null,
        passwordConf: null
      }
    }
  },
  model: {
    prop: 'visible',
    event: 'change'
  },
  methods: {
    close () {
      this.$emit('change', false)
    },
    change (val) {
      this.$emit('change', val)
    },
    submit () {
      if (this.passwordConf !== this.password) {
        this.errors.passwordConf = ['Senhas digitadas são diferentes']
        return
      }

      this.loading = true

      this.$store
        .dispatch('user/updatePassword', {
          oldPassword: this.password_old,
          newPassword: this.password
        })
        .then(() => {
          this.loading = false
          this.$message.success('Senha alterada com sucesso')
          this.password = ''
          this.password_old = ''
          this.close()
        })
        .catch(error => {
          this.errors = error.response.data
          if (this.errors.non_field_errors) {
            this.$message.error(this.errors.non_field_errors[0])
          }
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss">
.forgot-password {
  h3 {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
  }

  ::v-deep .form-control {
    border: 1px solid #6c757d !important;
  }

  ::v-deep .show-hide-password {
    position: absolute;
    right: 12px;
    top: 12px;
  }
}
</style>
