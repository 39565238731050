<template>
  <form class="account-payment-form" @submit.prevent="submit">
    <h3 class="text-center">Alterar dados de pagamento</h3>
    <p class="small text-center mb-3">
      Por favor, informe os dados do seu cartão de crédito:
    </p>

    <div class="text-center mb-4 pb-1">
      <img src="@/assets/images/cards.png" />
    </div>

    <form-group
      v-model="name"
      id="name"
      type="text"
      placeholder="Nome como está no cartão"
      :errors="errors.holder_name"
      autocomplete="cc-name"
    />

    <form-group
      v-model="number"
      id="number"
      type="tel"
      placeholder="Número do cartão"
      :mask="'#### #### #### ####'"
      :errors="errors.number"
      autocomplete="cc-number"
    />

    <form-group
      v-model="date"
      id="date"
      type="tel"
      placeholder="Data de validade (MM/AA)"
      :mask="'##/####'"
      :errors="dateErrors"
      autocomplete="cc-exp"
    />

    <form-group
      v-model="cvv"
      id="cvv"
      type="tel"
      placeholder="Código de segurança (CVV)"
      :mask="'####'"
      :errors="errors.cvv"
    />

    <div class="d-flex align-items-center justify-content-between pt-3">
      <button
        type="button"
        class="btn btn-outline-secondary btn-lg px-5"
        @click="close"
      >
        Cancelar
      </button>
      <button
        type="submit"
        class="btn btn-success btn-lg px-5"
        :disabled="loading"
      >
        <loading :show="loading">Salvar</loading>
      </button>
    </div>
  </form>
</template>

<script>
import get from 'lodash/get'
import concat from 'lodash/concat'

export default {
  data () {
    return {
      loading: false,
      name: '',
      number: '',
      date: '',
      cvv: '',
      cpf: '',
      errors: {
        number: [],
        holder_name: [],
        exp_month: [],
        exp_year: [],
        holder_document: [],
        cvv: []
      }
    }
  },
  computed: {
    dateErrors () {
      return concat(
        get(this.errors, 'exp_month', []),
        get(this.errors, 'exp_year', [])
      )
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    submit () {
      this.$message.hide()
      this.loading = true
      const month = this.date.slice(0, 2)
      const year = this.date.slice(-2)
      const coupon = window.localStorage.getItem('planref')

      this.$store
        .dispatch('user/card', {
          number: this.number,
          name: this.name,
          month,
          year,
          cvv: this.cvv,
          coupon
        })
        .then(() => {
          this.$message.success('Dados alterados com sucesso')
          this.close()
        })
        .catch(error => {
          this.errors = error.response.data
          if (this.errors.non_field_errors) {
            this.$message.error(this.errors.non_field_errors[0])
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.account-payment-form {
  ::v-deep .form-control {
    border: 1px solid #6c757d !important;
  }
}
</style>
