<template>
  <div class="view-account container">
    <div class="header-spacing"></div>

    <div class="section-header">
      Conta

      <div class="actions">
        <button @click="passwordDialog = true">Alterar senha</button>
        |
        <button @click="editingProfile = true">Editar dados</button>
      </div>
    </div>

    <change-password v-model="passwordDialog" />

    <profile
      :editing="editingProfile"
      @close="editingProfile = false"
    />

    <!-- <div class="section-header mt-5">
      Plano ativo

      <div class="actions">
        <button @click="cancelDialog = true">Cancelar</button>
      </div>
    </div>

    <active-plan />

    <div class="section-header mt-5">
      Detalhes da assinatura

      <div class="actions">
        <button @click="paymentDialog = true">Alterar dados</button>
      </div>
    </div>

    <credit-card class="mb-3" />

    <small class="opacity-60">*A mensalidade é cobrada no início de cada mês.</small> -->

    <confirmation
      v-model="cancelDialog"
      @confirm="cancel"
      title="Tem certeza que deseja cancelar a assinatura?"
      text="Ao confirmar, você será desconectado(a) da nossa plataforma e só poderá acessar novamente quando realizar o pagamento novamente."
      confirm-button-text="Sim"
      cancel-button-text="Não"
      confirm-button-class="btn-danger"
    />

    <b-modal
      v-model="paymentDialog"
      hide-footer
      hide-header
      centered
    >
      <payment-form @close="paymentDialog = false" />
    </b-modal>
  </div>
</template>

<script>
import Profile from '@/components/account/Profile'
// import ActivePlan from '@/components/account/ActivePlan'
// import CreditCard from '@/components/account/CreditCard'
import ChangePassword from '@/components/account/ChangePassword'
import PaymentForm from '@/components/account/PaymentForm'

export default {
  components: {
    Profile,
    // ActivePlan,
    // CreditCard,
    ChangePassword,
    PaymentForm
  },
  data () {
    return {
      editingProfile: false,
      passwordDialog: false,
      cancelDialog: false,
      paymentDialog: false
    }
  },
  methods: {
    cancel () {
      this.$store.dispatch('subscriptions/cancel').then(() => {
        this.$user.logout()
      }).catch(() => {
        this.$message.error('Erro! Entre em contato com o suporte')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.view-account {
  .section-header {
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.6);
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    padding-bottom: 8px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    .actions {
      button {
        background: transparent;
        border: none;
        color: white;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
}
</style>
