<template>
  <div class="image-input">
    <input
      type="file"
      style="display: none"
      ref="fileInputElement"
      @change="pickFile"
      accept="image/png, image/jpeg"
    />

    <button type="button" :style="buttonStyle" @click="openFilePicker">
      <i v-if="!url" class="icon-user"></i>

      <div class="icon-wrapper">
        <i class="icon-camera"></i>
      </div>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: null
    }
  },
  computed: {
    buttonStyle () {
      const style = {}
      if (this.url) {
        style['background-image'] = `url(${this.url})`
      }
      return style
    }
  },
  methods: {
    openFilePicker () {
      this.$refs.fileInputElement.click()
    },
    pickFile () {
      const selectedFile = this.$refs.fileInputElement.files[0]
      if (
        selectedFile &&
        ['image/png', 'image/jpeg'].includes(selectedFile.type)
      ) {
        const reader = new FileReader()
        reader.addEventListener('load', () => {
          this.$emit('pick', reader.result)
        }, false)
        reader.readAsDataURL(selectedFile)
      }
      this.$refs.fileInputElement.value = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.image-input {
  button {
    max-width: 60px;
    min-width: 60px;
    max-height: 60px;
    min-height: 60px;
    border-radius: 8px;
    background-color: #818181;
    background-size: cover;
    background-position-x: center;
    background-position-y: center;
    overflow: hidden;
    border: none;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 40px;

    .icon-wrapper {
      position: absolute;
      background: rgba(0, 0, 0, 0.5);
      bottom: 0;
      left: 0;
      right: 0;
      top: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 18px;
    }
  }
}
</style>
